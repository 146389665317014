.form-container {

  display: flex !important;
  justify-content: center;
  padding: 24px;

  h1 {
    text-align: center;
  }
  
  button {
    padding: 10px;
    background-color: #00788d;

    &:hover {
      background-color: #00434f;
    }
  }

  img {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }

  .intro-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
  }

  .wrapper-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    max-width: 1000px;
  }

  .status-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    max-width: 900px;
  }

  .recaptcha-wrapper {
    display: flex;
    justify-content: center;
    margin: 21px;
  }

}

#category {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}